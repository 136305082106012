// actions/categoryActions.js

import axios from 'axios';
import {
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  ADD_CATEGORY,
  REMOVE_CATEGORY,
  ADD_SUBCATEGORY,
  REMOVE_SUBCATEGORY,
  FETCH_SUBCATEGORIES_SUCCESS,
  FETCH_SUBCATEGORIES_FAILURE
} from '../constants/categoryConstant';


export const fetchCategories = () => async (dispatch) => {
  try {
    // console.log('Fetching categories...'); // Add this console.log
    const { data } = await axios.get('/api/categories');
    // console.log('Fetched categories:', data); // Add this console.log
    dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    console.error('Error fetching categories:', error.message); // Add this console.error
    dispatch({ type: FETCH_CATEGORIES_FAILURE, payload: error.message });
  }
};



export const addCategory = (categoryName) => async (dispatch) => {
  // console.log('Data sent to addCategory API:', categoryName); 
  try {
    const { data } = await axios.post('/api/categories', { name: categoryName });
    dispatch({ type: ADD_CATEGORY, payload: data });
    // console.log('Data received after adding category:', data);
  } catch (error) {
    console.error('Error adding category:', error); 
  }
};


export const removeCategory = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/categories/${id}`);
    dispatch({ type: REMOVE_CATEGORY, payload: id });
  } catch (error) {
    console.error('Error removing category:', error);
  }
};

export const fetchSubcategoriesSuccess = (subcategories) => ({
  type: FETCH_SUBCATEGORIES_SUCCESS,
  payload: subcategories,
});

export const fetchSubcategoriesFailure = (error) => ({
  type: FETCH_SUBCATEGORIES_FAILURE,
  payload: error,
});

export const fetchSubcategories = (categoryId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/categories/${categoryId}/subcategories`);
      dispatch(fetchSubcategoriesSuccess(response.data));
    } catch (error) {
      dispatch(fetchSubcategoriesFailure(error.message));
    }
  };
};

export const addSubcategory = (subcategoryData) => async (dispatch) => {
  const { name, category } = subcategoryData;
  // Use `name` and `category` variables in your action logic
  console.log('Data sent to addSubCategory API:', subcategoryData); 
  try {
      const { data } = await axios.post(`/api/categories/${category}/subcategories`, { name });
      dispatch({ type: ADD_SUBCATEGORY, payload: { categoryId: category, subcategory: data } });
      console.log('Data received after adding subcategory:', data);
  } catch (error) {
      console.error('Error adding subcategory:', error);
  } 
};


export const removeSubcategory = (categoryId, subcategoryId) => async (dispatch) => {
  try {
    await axios.delete(`/api/categories/${categoryId}/subcategories/${subcategoryId}`);
    dispatch({ type: REMOVE_SUBCATEGORY, payload: { categoryId, subcategoryId } });
  } catch (error) {
    console.error('Error removing subcategory:', error);
  }
};
