import React, {useState, useEffect} from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Mainpage from "./components/Mainpage";
import Products from "./components/Products";
// import Login from "./components/admin/Login";
import Register from "./components/admin/Register";
import Admindash from "./components/admin/AdminDash";
import ProductDash from "./components/admin/ProductDash";
import CategoryDash from "./components/admin/CatDash";
import Cart from "./components/Cart/Cart";
import Shipping from "./components/Cart/Shipping";
import ConfirmOrders from "./components/Cart/ConfirmOrders.js";
import ProductDetails from "./components/ProductDetails.js";
import Footer from "./partials/Footer.js";
import Header from "./partials/Header.js";
import Profile from "./components/Profile.js";
import LoginSignup from "./components/user/LoginSignup";
import About from "./components/About.js";
import Terms from "./components/Terms.js";
// import AuthRoute from "./AuthRoute.js"
import OrderDash from "./components/admin/OrderDash.js";
import AdminHeader from "./components/admin/partials/AdminHeader.js";


function App() {
  const isAdminPath = window.location.pathname.includes('dash'); // Check if the current path is under admin path
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    // Implement your logic to check if the user is logged in
    // For example, you can check if there is a token in local storage
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set isLoggedIn to true if token exists
  }, []);
  return (
    <BrowserRouter>
    {isAdminPath ? <AdminHeader /> : <Header />}
      
      <Routes id="main-container">
        {/* User Path */}
        <Route path="/" element={<Mainpage />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductDetails />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/orders/confirm" element={<ConfirmOrders />} />
        <Route path="/user/login" element={<LoginSignup />} />
        <Route path="/user/profile" element={<Profile />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms-and-conditions" element={<Terms />} />

        
        
        {/* Admin Path */}
        <Route path="/dashboard" element={<Admindash />} />
        <Route path="/productdash" element={<ProductDash />} />
        <Route path="/categorydash" element={<CategoryDash />} />
        <Route path="/orderdash" element={<OrderDash />} />
        
        
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
