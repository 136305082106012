// store.js

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { cartReducer } from './reducer/CartReducer';
import { authReducer } from './reducer/AuthReducer';
import categoryReducer from './reducer/categoryReducer';  
import { userReducer } from './reducer/UserReducer';
import productsReducer from './reducer/productReducer';


const reducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  auth: authReducer,
  category: categoryReducer,
  product: productsReducer, 
});

let initialState = {
  cart: {
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
    shippingInfo: localStorage.getItem('shippingInfo')
      ? JSON.parse(localStorage.getItem('shippingInfo'))
      : {},
  },
  category: {
    categoryItems: localStorage.getItem('categoryItems')
      ? JSON.parse(localStorage.getItem('categoryItems'))
      : [],
  },
  auth: {
    isAuthenticated: false,
    user: null, // You can add more user related fields here
  },
  product: {
    products: [], // Initial product state
  },
};

const middleware = [thunk];

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
);

const store = createStore(
  reducer,
  initialState,
  enhancer
);


export default store;
