// reducer/categoryReducer.js

import { ADD_CATEGORY, REMOVE_CATEGORY, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE, ADD_SUBCATEGORY, REMOVE_SUBCATEGORY, FETCH_SUBCATEGORIES_SUCCESS, FETCH_SUBCATEGORIES_FAILURE } from '../constants/categoryConstant';

const initialState = {
  categories: [],
  subcategories: [], // Initialize subcategories as an empty array
  loading: false,
  error: null
};

const categoryReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      // console.log('Current state before update:', state);
      console.log('Fetched categories data:', action.payload);

      return {
        ...state,
        categoryItems: action.payload,
        loading: false, // Optionally, update loading state
        error: null     // Optionally, reset error state
      };
    case FETCH_CATEGORIES_FAILURE:
      return { ...state, error: action.payload };
    case ADD_CATEGORY:
      return { ...state, categoryItems: [...state.categoryItems, action.payload] };
    case REMOVE_CATEGORY:
      return { ...state, categoryItems: state.categoryItems.filter(category => category._id !== action.payload) };
    case ADD_SUBCATEGORY:
      const { categoryId, subcategory } = action.payload;
      return {
        ...state,
        categoryItems: state.categoryItems.map(category =>
          category._id === categoryId
            ? { ...category, subcategories: [...category.subcategories, subcategory] }
            : category
        )
      };
    case REMOVE_SUBCATEGORY:
      return {
        ...state,
        categoryItems: state.categoryItems.map(category => {
          if (category._id === action.payload.categoryId) {
            return {
              ...category,
              subcategories: category.subcategories.filter(subcategory => subcategory._id !== action.payload.subcategoryId)
            };
          }
          return category;
        })
      };
    case FETCH_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.payload,
        loading: false,
        error: null
      };
    case FETCH_SUBCATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default categoryReducer;
