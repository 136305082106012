
import Left from "./partials/Left"

function Admindash() {
    return (
        <>
        
        
        <section className="dash">
            <div className="container"> 
            <div className="row">
                <Left/>
                <div className="col-md-8"> </div>

            </div>

            </div>
        </section>

        </> 
     );
}

export default Admindash;