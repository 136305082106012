// ProductDash.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Left from './partials/Left';
import { fetchCategories, fetchSubcategories } from '../../actions/categoryAction';
import imageCompression from 'browser-image-compression';

const ProductDash = () => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.category.categoryItems);
  console.log('categories', categories)
  const subcategories = useSelector(state => state.category.subcategories);
  const [products, setProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [newProduct, setNewProduct] = useState({
    image: null,
    name: '',
    shortDescription: '',
    rating: '',
    price: '',
    offPrice: '',
    category: '',
    subCategory: '',
  });


  // const handleFileChange = (e, field) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const previewUrl = file && (file instanceof Blob || file instanceof File) ? URL.createObjectURL(file) : null;

  //     if (field === 'image') {
  //       console.log('Selected file:', file);
  //       setNewProduct((prevProduct) => ({
  //         ...prevProduct,
  //         [field]: file,
  //       }));
  //       setImagePreviewUrl(previewUrl);
  //     }
  //   } else {
  //     console.error('No file selected');
  //     alert('Please select an image');
  //   }
  // };

  const handleFileChange = async (e, field) => {
    const file = e.target.files[0];
  
    if (file) {
      try {
        // Check if the file size exceeds 2MB
        if (file.size > 2 * 1024 * 1024) {
          // Options for compressing the image
          const options = {
            maxSizeMB: 2, // Maximum size in MB
            maxWidthOrHeight: 1024, // Maximum width or height of the image
            useWebWorker: true, // Use web workers to offload the compression task
          };
  
          // Compress the image
          const compressedFile = await imageCompression(file, options);
          const previewUrl = URL.createObjectURL(compressedFile);
  
          if (field === 'image') {
            console.log('Selected file:', compressedFile);
            setNewProduct((prevProduct) => ({
              ...prevProduct,
              [field]: compressedFile,
            }));
            setImagePreviewUrl(previewUrl);
          }
        } else {
          const previewUrl = URL.createObjectURL(file);
  
          if (field === 'image') {
            console.log('Selected file:', file);
            setNewProduct((prevProduct) => ({
              ...prevProduct,
              [field]: file,
            }));
            setImagePreviewUrl(previewUrl);
          }
        }
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    } else {
      console.error('No file selected');
      alert('Please select an image');
    }
  };

  const handleProductInputChange = (e) => {
    setNewProduct({
      ...newProduct,
      [e.target.name]: e.target.value
    });
  };



  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (newProduct.category) {
      const selectedCategory = categories.find(cat => cat.name === newProduct.category);
      if (selectedCategory) {
        dispatch(fetchSubcategories(selectedCategory._id));
      }
    }
  }, [newProduct.category, categories, dispatch]);


  const handleAddProduct = async (e) => {
    e.preventDefault();

    console.log('Adding product...');

    try {
      const formData = new FormData();
      formData.append("image", newProduct.image);
      formData.append("name", newProduct.name);
      formData.append("shortDescription", newProduct.shortDescription);
      formData.append("rating", newProduct.rating);
      formData.append("price", newProduct.price);
      formData.append("offPrice", newProduct.offPrice);
      formData.append("category", newProduct.category);
      formData.append("subCategory", newProduct.subCategory);

      const response = await fetch('http://localhost:5000/api/products', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setProducts([...products, data]);

        setNewProduct({
          image: null,
          name: '',
          shortDescription: '',
          rating: '',
          price: '',
          offPrice: '',
          category: '',
          subCategory: '',
        });
      } else {
        console.error('Failed to add product');
      }
    } catch (err) {
      console.error('Error adding product', err);
    }
    setShowForm(false);
  };

  const handleEditProduct = index => {
    const productToEdit = products[index];
    setSelectedProductIndex(index);
    setNewProduct(productToEdit);
    setImagePreviewUrl(productToEdit.image);
    setShowForm(true);
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", newProduct.image);
      formData.append("name", newProduct.name);
      formData.append("shortDescription", newProduct.shortDescription);
      formData.append("rating", newProduct.rating);
      formData.append("price", newProduct.price);
      formData.append("offPrice", newProduct.offPrice);
      formData.append("category", newProduct.category);
      formData.append("subCategory", newProduct.subCategory);
  
      const Id = products[selectedProductIndex]._id;
  
      const response = await fetch(`http://localhost:5000/api/products/${Id}`, {
        method: 'PUT',
        body: formData,
      });
  
      if (response.ok) {
        const updatedProducts = [...products];
        updatedProducts[selectedProductIndex] = newProduct;
        setProducts(updatedProducts);
        setNewProduct({
          image: null,
          name: '',
          shortDescription: '',
          rating: '',
          price: '',
          offPrice: '',
          category: '',
          subCategory: '',
        });
        setImagePreviewUrl(null);
        setSelectedProductIndex(null);
        setShowForm(false);
      } else {
        console.error('Failed to update product');
      }
    } catch (err) {
      console.error('Error updating product', err);
    }
  };

  const handleDeleteProduct = async (index) => {
    try {
      const Id = products[index]._id;
      const response = await fetch(`http://localhost:5000/api/products/${Id}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        // Remove the product from the state
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);
      } else {
        console.error('Failed to delete product');
        // Handle error appropriately, maybe show a message to the user
      }
    } catch (err) {
      console.error('Error deleting product', err);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/products');
        if (response.ok) {
          const data = await response.json();
          console.log('fetch data',data);
          setProducts(data);
        } else {
          console.error('Failed to fetch products:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>

      <section className="dash">
        <div className="container">
          <div className="row">
            <Left />
            <div className="col-md-8">
              <h1>Product Dashboard</h1>

              <div>
                <button
                  onClick={() => setShowForm(!showForm)}
                  className="btn btn-success form-control my-3"
                >
                  {showForm ? "Hide Form" : "Add Product"}
                </button>
                {showForm && (
                  <div className="card">
                    <div className="card-header">
                      <h2>
                        {selectedProductIndex !== null ? "Edit" : "Add"} Product
                      </h2>
                    </div>
                    <div className="card-body">
                      <form
                        onSubmit={
                          selectedProductIndex !== null
                            ? handleUpdateProduct
                            : handleAddProduct
                        }
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <label>Image:</label>
                          <input
                            type="file"
                            name="image"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, "image")}
                            className="form-control"
                          />
                          {selectedProductIndex !== null
                            ? imagePreviewUrl && (
                              <img
                                src={imagePreviewUrl}
                                alt="Update Product"
                                width={"100px"}
                                className="img-thumbnail mt-2"
                              />
                            )
                            : imagePreviewUrl && (
                              <img
                                src={imagePreviewUrl}
                                alt="Add Product"
                                width={"100px"}
                                className="img-thumbnail mt-2"
                              />
                            )}
                        </div>
                        <div className="form-group">
                          <label>Name:</label>
                          <input
                            type="text"
                            name="name"
                            value={newProduct.name}
                            onChange={handleProductInputChange}
                            className="form-control"
                            minLength="10"
                            maxLength="50"
                          />
                        </div>
                        <div className="form-group">
                          <label>Short Description:</label>
                          <input
                            type="text"
                            name="shortDescription"
                            value={newProduct.shortDescription}
                            onChange={handleProductInputChange}
                            className="form-control"
                            minLength="20"
                            maxLength="100"
                          />
                        </div>
                        <div className="form-group">
                          <label>Rating:</label>
                          <input
                            type="number"
                            name="rating"
                            value={newProduct.rating}
                            onChange={handleProductInputChange}
                            className="form-control"
                            step="0.1"
                            min="1"
                            max="5"
                          />
                        </div>
                        <div className="form-group">
                          <label>Price:</label>
                          <input
                            type="number"
                            name="price"
                            value={newProduct.price}
                            onChange={handleProductInputChange}
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label>OffPrice:</label>
                          <input
                            type="number"
                            name="offPrice"
                            value={newProduct.offPrice}
                            onChange={handleProductInputChange}
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label> Product Category:</label>
                          <select
                            name="category"
                            value={newProduct.category}
                            onChange={handleProductInputChange}
                            className="form-control"
                          >
                            <option value="">Select a Category....</option>
                            {categories.map((category) => (
                              <option key={category._id} value={category.name}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {newProduct.category && subcategories && (
                          <div className="form-group">
                            <label>Sub Category:</label>
                            <select
                              name="subCategory"
                              value={newProduct.subCategory}
                              onChange={handleProductInputChange}
                              className="form-control"
                            >
                              <option value="">Select a Subcategory....</option>
                              {subcategories.map((subCategory) => (
                                <option key={subCategory._id} value={subCategory.name}>
                                  {subCategory.name}
                                </option>
                              ))}
                            </select>

                          </div>
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary mt-2 form-control"
                        >
                          {selectedProductIndex !== null ? "Update" : "Add"}{" "}
                          Product
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>

              {!showForm && (
                <div>
                  <h2>Product List</h2>
                  <table className="table table-dark table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Short Description</th>
                        <th>Rating</th>
                        <th>Price</th>
                        <th>Off Price</th>
                        <th>Product Category</th>
                        <th>Sub Category</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products?.length > 0 ? (
                        products.map((product, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {product.image && typeof product.image === 'string' && (
                                <img
                                  src={`http://localhost:5000/${product.image}`}
                                  alt={`${product.image}`}
                                  width={"50px"}
                                  className="img-thumbnail"
                                />
                              )}
                            </td>
                            <td>{product.name}</td>
                            <td>{product.shortDescription}</td>
                            <td>{product.rating}</td>
                            <td>{product.price}</td>
                            <td>{product.offPrice}</td>
                            <td>{product.category}</td>
                            <td>{product.subCategory}</td>
                            <td>
                              <button
                                onClick={() => handleEditProduct(index)}
                                className="btn btn-primary btn-sm mr-2"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteProduct(index)}
                                className="btn btn-danger btn-sm"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductDash;
