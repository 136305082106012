import React, { useRef, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import { addItemsToCart } from "../actions/CartAction";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { SERVER_URL } from "../constants/ServerConstant";
import { fetchCategories, fetchSubcategories } from '../actions/categoryAction';


const Products = () => {
  const dispatch = useDispatch();
  const { categoryItems } = useSelector(state => state.category);
  // console.log('categoryItems', categoryItems)

  const [subcategoriesMap, setSubcategoriesMap] = useState(new Map());
  // console.log('subcategoriesMap', subcategoriesMap)
  const [rating, setRating] = useState(4.3);
  const [products, setProducts] = useState([]);
  const [showPreloader, setShowPreloader] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [showFullDescriptions, setShowFullDescriptions] = useState({});
  const [sortBy, setSortBy] = useState("category"); // State to track sorting option
  const [priceSortOrder, setPriceSortOrder] = useState("asc"); // State to track sort order for price
  const [ratingSortOrder, setRatingSortOrder] = useState("asc"); // State to track sort order for rating
  const [quantity, setQuantity] = useState(1);
  const { cartItems } = useSelector((state) => state.cart);
  const alert = useAlert();

  const handleClick = (productId) => {
    setShowFullDescriptions((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  // console.log(`SERVER_URL: ${SERVER_URL}`)

  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/categories/${categoryId}/subcategories`);
      const data = await response.json();
      const subcategories = subcategoriesMap;
      subcategories.set(categoryId, data);
      setSubcategoriesMap(new Map(subcategories));
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  // Fetch subcategories for each category when the component mounts or when a new category is added
  useEffect(() => {
    categoryItems.forEach(category => {
      fetchSubcategories(category._id);
    });
  }, [categoryItems]);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let url = `${SERVER_URL}/api/products`;
        if (selectedCategory) {
          url += `?category=${selectedCategory}`;
        }
        const response = await fetch(url);
        const data = await response.json();
        // console.log("Fetched Products user product page:", data);
        setProducts(data);
        setShowPreloader(false);
      } catch (error) {
        setError(error);
        setShowPreloader(false);
      }
    };
    

    fetchProducts();
  }, [selectedCategory]);

  // Filter products based on selected category
const filteredProducts = selectedCategory ? products.filter(product => product.category === selectedCategory) : products;

  // Sort products based on sort order and selected sort option
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortBy === "category") {
      return a.category.localeCompare(b.category);
    } else {
      if (sortBy === "price") {
        return priceSortOrder === "asc" ? a.price - b.price : b.price - a.price;
      } else if (sortBy === "rating") {
        return ratingSortOrder === "asc" ? a.rating - b.rating : b.rating - a.rating;
      }
    }
  });

  const handlePriceSort = (order) => {
    // Apply the selected sorting order for price
    setPriceSortOrder(order);
    setSortBy("price");
  };

  const handleRatingSort = (order) => {
    // Apply the selected sorting order for rating
    setRatingSortOrder(order);
    setSortBy("rating");
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const imageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 200,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  ); // Use sortedProducts

  // added by vijay shankar handleAddToCart
  const handleAddToCart = (productId) => {
    // Add the product with the given id to the cart
    const selectedProduct = currentProducts.find(
      (product) => product._id === productId
    );
    const cartItemExist = cartItems.find((item) => item.product === productId);
    // console.log("cartItemExist :", cartItemExist);

    if (!cartItemExist) {
      // If the product is not in the cart, add it with a quantity of 1
      dispatch(addItemsToCart(productId, 1));
      alert.success("Item added to cart successfully");
    }
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setShowPreloader(false);

    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
      setShowPreloader(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showPreloader && (
        <div className="preload" data-preaload>
          <div className="circle"></div>
          <p className="text">EIKO</p>
          <p>Patisserie & Bakehouse</p>
        </div>
      )}

      {!showPreloader && products.length > 0 && (
        <>
          {showBackToTop && (
            <button
              className="btn btn-primary back-to-top"
              title="Back to Top"
              onClick={scrollToTop}
            >
              <i className="bi bi-arrow-up"></i>
            </button>
          )}


          <div className="productcontainer">
            <div className="container">
              <div className="row">
                <div className="col-3">
                  {/* show category and subcategy in ul li tags */}
                  <ul className="list-group">
                    {categoryItems.map((category) => (
                      <li key={category._id} className="list-group-item border-0 flex-start justify-content-between align-items-center">
                        <span>{category.name}</span>

                        {/* <button onClick={() => toggleSubcategories(category._id)} className="btn btn-primary btn-sm ml-auto">
                                            <i className={`bi ${subcategoriesMap.has(category._id) ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                        </button> */}
                        {subcategoriesMap.has(category._id) && (
                          <ul>
                            {/* <span>Subcategories:</span> */}
                            {subcategoriesMap.get(category._id).map((subcategory, index) => (
                              <li key={subcategory._id} className='d-flex justify-content-between align-items-center'>
                                <span>{subcategory.name}</span>

                              </li>
                            ))}
                          </ul>
                        )}


                      </li>
                    ))}
                  </ul>

                </div>

                <div className="col-9">
                  <div className="product-list container">
                    <div className="row">
                      <section>
                        <div className="container mb-3">
                          <div className="row">
                            <div className="col-md-4">
                              {/* <select
                                className="form-select"
                                value={selectedCategory || ""}
                                onChange={(e) => setSelectedCategory(e.target.value || null)}
                              >
                                <option value="">All Categories</option>
                                {categoryItems.map((category) => (
                                  <option key={category._id} value={category._id}>
                                    {category.name}
                                  </option>
                                ))}
                              </select> */}

                            </div>
                            <div className="col-md-4">

                              <select
                                className="form-select"
                                value={priceSortOrder}
                                onChange={(e) =>
                                  handlePriceSort(e.target.value)
                                }
                              >
                                <option value="asc">Sort by Price.....</option>
                                <option value="asc">Price Low To High &#8679;</option>
                                <option value="desc">Price High To Low &#8681; </option>
                              </select>
                            </div>
                            <div className="col-md-4">

                              <select
                                className="form-select"
                                value={ratingSortOrder}
                                onChange={(e) =>
                                  handleRatingSort(e.target.value)
                                }
                              >
                                <option value="asc">Sort by Rating.....</option>
                                <option value="asc">Rating Low To High &#8679;</option>
                                <option value="desc">Rating High To Low &#8681; </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </section>
                      {currentProducts.map((product, index) => (
                        <div className="col-md-6 col-lg-4" key={product._id}>
                          <Link to={`/products/${product._id}`}>
                            <div className="card mb-4 border-0 shadow">
                              <animated.img
                                src={`${SERVER_URL}/${product.image}`}
                                alt={product.name}
                                className="card-img-top"
                                style={imageProps}
                              />
                              <div className="card-body">
                                <h2 className="card-title product-card text-uppercase">
                                  {product.name}
                                </h2>
                                <h6 className="card-text product-desc fw-light">
                                  <td>
                                    {showFullDescriptions[product._id] ? (
                                      <>
                                        {product.shortDescription}{" "}
                                        <Link
                                          onClick={() =>
                                            handleClick(product._id)
                                          }
                                        >
                                          <i className="bi bi-chevron-up"></i>
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        {product.shortDescription.length >
                                          60 ? (
                                          <span>
                                            {product.shortDescription.substring(
                                              0,
                                              60
                                            )}{" "}
                                            <Link
                                              onClick={() =>
                                                handleClick(product._id)
                                              }
                                            >
                                              <i className="bi bi-chevron-down"></i>
                                            </Link>
                                          </span>
                                        ) : (
                                          product.shortDescription
                                        )}
                                      </>
                                    )}
                                  </td>
                                </h6>
                                <p className="card-text product-rating">
                                  <i className="bi bi-star-fill"></i>
                                  <strong> {product.rating}</strong>
                                </p>
                              </div>
                              <div className="product-info">
                                <strong className="product-price">
                                  ₹{product.offPrice}
                                </strong>
                                <span className="product-off-price">
                                  ₹{product.price}
                                </span>
                                <button
                                  onClick={() => handleAddToCart(product._id)}
                                  className="product-add-to-cart "
                                >
                                  ADD
                                </button>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <nav className="Page navigation example">
            <ul className="pagination justify-content-center">
              {Array.from(
                { length: Math.ceil(products.length / productsPerPage) },
                (_, i) => (
                  <li
                    key={i + 1}
                    className={
                      currentPage === i + 1 ? "page-item active" : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </a>
                  </li>
                )
              )}
            </ul>
          </nav>

          {/* <Footer /> */}

          {error && <div>Error: {error.message}</div>}
        </>
      )}
    </>
  );
};

export default Products;
