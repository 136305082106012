// reducer/productReducers.js

import * as types from '../constants/productConstant';

const initialState = {
  loading: false,
  error: null,

};

const productsReducer = (state = initialState, action) => {
  console.log('Action received:', action.payload);

  switch (action.type) {
    case types.ADD_PRODUCT_REQUEST:
    case types.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case types.ADD_PRODUCT_SUCCESS:
    case types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case types.ADD_PRODUCT_FAILURE:
    case types.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case types.DELETE_PRODUCT:
      return {
        ...state,
        products: state.products.filter((product) => product._id !== action.payload),
      };
    case types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        error: null,
      };
    case types.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;


