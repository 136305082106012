import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, addCategory, removeCategory, addSubcategory, removeSubcategory } from '../../actions/categoryAction'; // Redux actions for category

import Left from "./partials/Left";

function CategoryDash() {
    const [newCategory, setNewCategory] = useState('');
    const [newSubcategory, setNewSubcategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subcategoriesMap, setSubcategoriesMap] = useState(new Map());
    const dispatch = useDispatch();
    const { categoryItems } = useSelector((state) => state.category);
    // console.log('categoryItems', categoryItems);
    useEffect(() => {
        dispatch(fetchCategories());
    }, [dispatch]);


    const handleAddCategory = () => {
        // console.log('New Category catDash.js:', newCategory); // Log the newCategory state
        if (!newCategory.trim()) {
            return;
        }
        dispatch(addCategory(newCategory)); // Pass the category name directly
        setNewCategory('');
    };


    const handleDeleteCategory = (categoryId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this category?");
        if (confirmDelete) {
            dispatch(removeCategory(categoryId));
        }
    };
    const handleAddSubcategory = () => {
        console.log('New SubCategory catDash.js:', newSubcategory, selectedCategory); // Log the newCategory state
        if (!newSubcategory.trim() || !selectedCategory) {
            return;
        }
        dispatch(addSubcategory({ name: newSubcategory, category: selectedCategory }));
        setNewSubcategory('');
    };
    // const handleCategoryChange = async (categoryId) => {
    //     setSelectedCategory(categoryId);
    //     if (!subcategoriesMap.has(categoryId)) {
    //         await fetchSubcategories(categoryId);
    //     }
    // };
    const fetchSubcategories = async (categoryId) => {
        try {
            const response = await fetch(`/api/categories/${categoryId}/subcategories`);
            const data = await response.json();
            const subcategories = subcategoriesMap;
            subcategories.set(categoryId, data);
            setSubcategoriesMap(new Map(subcategories));
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };
    // Fetch subcategories for each category when the component mounts or when a new category is added
    useEffect(() => {
        categoryItems.forEach(category => {
            fetchSubcategories(category._id);
        });
    }, [categoryItems]);

    // const toggleSubcategories = (categoryId) => {
    //     const subcategories = subcategoriesMap;
    //     if (subcategories.has(categoryId)) {
    //         subcategories.delete(categoryId);
    //     } else {
    //         fetchSubcategories(categoryId);
    //     }
    //     setSubcategoriesMap(new Map(subcategories));
    // };
    const handleDeleteSubcategory = (categoryId, subcategoryId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this subcategory?");
        if (confirmDelete) {
            dispatch(removeSubcategory(categoryId, subcategoryId));
            const subcategories = subcategoriesMap.get(categoryId).filter(subcategory => subcategory._id !== subcategoryId);
            setSubcategoriesMap(new Map(subcategoriesMap.set(categoryId, subcategories)));
        }
    };


    return (
        <>
            <section className="dash">
                <div className="container mb-2">
                    <div className="row">
                        <Left />
                        <div className="col-md-8">
                            <h1>Category Dashboard</h1>
                            <div className="form-group">
                                <label>Add Category:</label>
                                <div className="input-group mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newCategory}
                                        onChange={(e) => setNewCategory(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={handleAddCategory} className="btn btn-success ms-3">Add</button>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Add Subcategory:</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newSubcategory}
                                        onChange={(e) => setNewSubcategory(e.target.value)}
                                    />
                                    <select
                                        className="custom-select"
                                        value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                        style={{ border: '1px solid #ccc', padding: '8px 12px', fontSize: '16px', outline: 'none', background: 'grey', color:'#fff' }}
                                        >
                                        <option value="">Select Category</option>
                                        {categoryItems.map((category) => (
                                            <option key={category._id} value={category._id}>{category.name}</option>
                                        ))}
                                    </select>
                                    <div className="input-group-append">
                                        <button onClick={handleAddSubcategory} className="btn btn-success ms-3">Add</button>
                                    </div>
                                </div>
                            </div>


                            <h2>Categories</h2>
                            <ul className="list-group">
                                {categoryItems.map((category, index) => (
                                    <li key={category._id} className="list-group-item flex-start justify-content-between align-items-center">
                                        <span>{index + 1}. {category.name}</span>
                                        <button onClick={() => handleDeleteCategory(category._id)} className="btn btn-danger btn-sm ml-auto delete-btn"><i className="bi bi-trash"></i></button>
                                        {/* <button onClick={() => toggleSubcategories(category._id)} className="btn btn-primary btn-sm ml-auto">
                                            <i className={`bi ${subcategoriesMap.has(category._id) ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                                        </button> */}
                                        {subcategoriesMap.has(category._id) && (
                                            <ul>
                                                {/* <span>Subcategories:</span> */}
                                                {subcategoriesMap.get(category._id).map((subcategory, index) => (
                                                    <li key={subcategory._id} className='d-flex justify-content-between align-items-center'>
                                                        <span>- {subcategory.name}</span>
                                                        <button onClick={() => handleDeleteSubcategory(category._id, subcategory._id)} className="btn btn-sm ml-2 delete-btn" ><i className="bi bi-trash"></i></button>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        

                                    </li>
                                ))}
                            </ul>
                            {/* Display subcategories
                            <h2>Subcategories</h2>
                            <ul className="list-group">
                                {subcategories.map((subcategory) => (
                                    <li key={subcategory._id} className="list-group-item">
                                        {subcategory.name}
                                    </li>
                                ))}
                            </ul> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CategoryDash;
