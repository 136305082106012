
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const REMOVE_SUBCATEGORY = "REMOVE_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const FETCH_SUBCATEGORIES_SUCCESS = "FETCH_SUBCATEGORIES_SUCCESS";
export const FETCH_SUBCATEGORIES_FAILURE = "FETCH_SUBCATEGORIES_FAILURE";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
