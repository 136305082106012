import { Link } from "react-router-dom";

function Left() {
    return ( 
<>
<div className="col-md-3"> 
<Link  to='/productdash'> <button className="btn btn-secondary form-control p-3 mb-2"> Products Management</button></Link>
<Link  to='/categorydash'> <button className="btn btn-secondary form-control p-3 mb-2"> Category Management</button></Link>
<Link  to='/orderdash'> <button className="btn btn-secondary form-control p-3 mb-2"> Order Management</button></Link>
{/* <Link  to='/querymngt'> <button className="btn btn-secondary form-control p-3 mb-2"> Query Management</button></Link>
<Link  to='/coursemngt'> <button className="btn btn-secondary form-control p-3 mb-2"> Course Management</button></Link> */}




</div>



</>
        );
}

export default Left;